<template>
  <b-card>
    <p>ROSOUT</p>
    <p>file: {{rosMsgFile}}</p>
    <p>function: {{rosMsgFunction}}</p>
    <p>msg: {{rosMsgMsg}}</p>
    <p>name: {{rosMsgName}}</p>
    <p>{{rosMsgList}}</p>
  </b-card>
</template>

<script>
export default {
  props: [
    "rosMsgFile",
    "rosMsgFunction",
    "rosMsgMsg",
    "rosMsgName",
    "rosMsgList"
  ]
};
</script>

<style>
</style>