<template>
  <div>
    <ros ref="rosObject"></ros>
    <b-card>
      <p>コンソール表示ON/OFF</p>
      <label class="switch switch-pill switch-label switch-primary">
        <input type="checkbox" class="switch-input" v-model="buttonChecked" />
        <span class="switch-slider" data-checked="On" data-unchecked="Off"></span>
      </label>
    </b-card>
    <ValueBox
      v-bind:rosMsgFile="rosMsgFile"
      v-bind:rosMsgFunction="rosMsgFunction"
      v-bind:rosMsgMsg="rosMsgMsg"
      v-bind:rosMsgName="rosMsgName"
      v-bind:rosMsgList="rosMsgList"
    />
  </div>
</template>

<script>
import ROSLIB from "roslib";
import Ros from "../components/Ros";
import ValueBox from "../components/ConsoleValueBox";

export default {
  components: {
    Ros,
    ValueBox
  },
  data() {
    return {
      rosMsgFile: [],
      rosMsgFunction: [],
      rosMsgMsg: [],
      rosMsgName: [],
      rosMsgList: [],
      buttonChecked: []
    };
  },
  mounted() {
    this.setRosout();
  },
  methods: {
    setRosout() {
      var _this = this;
      this.distTopic = this.$refs.rosObject.createTopic("/rosout");
      this.distTopic.subscribe(function(message) {
        _this.rosMsgFile = message.file;
        _this.rosMsgFunction = message.function;
        _this.rosMsgMsg = message.msg;
        _this.rosMsgName = message.name;
        if (_this.buttonChecked.length == 1) {
          _this.rosMsgList.unshift(message);
          if (_this.rosMsgList.length >= 100) {
            _this.rosMsgList.pop();
          }
        }
      });
    }
  },
  beforeDestroy() {
    this.$refs.rosObject.ros.close();
  }
};
</script>
